import React from "react"
import { Page } from "../../../shared/Page/Page"
import { PageHeader } from "../../../shared/PageHeader/PageHeader"
import { H3, P, PreSection } from "../../../shared/Ui/Typography/Typography"
import { TextButton } from "../../../shared/Ui/TextButton/TextButton"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import media from "css-in-js-media"
import Helmet from "react-helmet"
import { Header } from "../../../shared/Header/Header"
import { Footer } from "../../../shared/Footer/Footer"
import { Link } from "gatsby"

const Container = styled.div`
    background: linear-gradient(
        ${({ theme }) => theme.surfaces.lighter},
        #f8fafc
    );
    box-shadow: ${({ theme }) => theme.surfaces.dark} 0 -50rem 50rem -50px inset;

    > div.content {
        display: flex;
        justify-content: flex-end;
        flex-direction: row-reverse;
        padding: 50rem 0 100rem;

        .text {
            max-width: 700rem;
            margin-left: 30rem;
            padding: 0 30rem;
            border-left: 1rem solid ${({ theme }) => theme.borders.normal};
        }

        .image {
            border-radius: 10rem;
            box-shadow: rgba(17, 12, 46, 0.15) 0 48rem 100rem 0;
        }

        ${media("<tablet")} {
            .text {
                border-left: none;
                padding: 0;
                margin-left: 0;
            }

            .image {
                display: none;
            }
        }
    }
`
const Industry = ({ location }) => {
    return (
        <Page disableCta disableFooter disableHeader location={location}>
            <Helmet>
                <title>Industry partnerships</title>
            </Helmet>
            <Container className={"overlapPageMargins"}>
                <Header constrained />
                <PageHeader
                    className={"pageConstrained"}
                    breadCrumbs={[
                        { title: "Company" },
                        { to: "/company/partnerships", title: "Partnerships" },
                        { title: "Industry" },
                    ]}
                    h1={"Industry partnerships"}
                    h2={
                        "Resale solutions for phone system suppliers and industry professionals"
                    }
                />
                <div className={"content pageConstrained"}>
                    <div className={"text"}>
                        <PreSection>Q4 2021 Program</PreSection>
                        <H3>Overview</H3>
                        <P>
                            We're always looking to work with phone system
                            suppliers, and industry professionals on the resale
                            of 23shout.
                        </P>
                        <P>
                            We offer a generous and comprehensive program for
                            suppliers and distributors across Australia.
                        </P>
                        <H3>Eligibility</H3>
                        <P>
                            To qualify for an industry partnership we require
                            prospective applicants to meet the following
                            criteria:
                            <ol>
                                <li>Be a registered business or company</li>
                                <li>Experience with supplying phone systems</li>
                                <li>
                                    Not subject to any fraud notices from
                                    23shout Pty Ltd
                                </li>
                            </ol>
                        </P>
                        <H3>Next steps</H3>
                        <P>
                            Interested? Great! Contact our team via{" "}
                            <a href={"mailto:partnerships@23shout.com"}>
                                <TextButton color={"primary"}>
                                    partnerships@23shout.com
                                </TextButton>
                            </a>{" "}
                            or via our{" "}
                            <Link to={`/support/contact`} tabIndex={-1}>
                                <TextButton color={"primary"}>
                                    contact form
                                </TextButton>
                            </Link>
                            .
                        </P>
                    </div>
                    <StaticImage
                        src={
                            "../../../images/company/partnerships/industry/partner.jpg"
                        }
                        alt={"Industry partners"}
                        layout={"fixed"}
                        width={300}
                        height={550}
                        className={"image"}
                        objectPosition={"80% 80%"}
                    />
                </div>
            </Container>
            <Footer constrained />
        </Page>
    )
}

export default Industry
